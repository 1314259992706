module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"LIEmobil website","short_name":"LIEmobil","description":"Official LIEmobil website.","start_url":"/","background_color":"#C8D700","theme_color":"#F4F7CC","lang":"de","display":"standalone","icon":"src/assets/images/favicon-310x310.png","localize":[{"start_url":"/de/","lang":"de","name":"LIEmobil website","short_name":"LIEmobil","description":"Official LIEmobil website."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"123934780e8a8e343fc68092d22f4b1b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/var/www/web/src/layouts/WrapperLayout.js"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://9c3d21da3e3b4e899f414b086ff8cdac@o406152.ingest.sentry.io/5560524","autoSessionTracking":true,"sampleRate":0.5,"enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#C8D700","showSpinner":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-redux/gatsby-browser.js'),
      options: {"plugins":[],"pathToCreateStoreModule":"./src/redux/createStore","serialize":{"space":0,"isJSON":true,"unsafe":false,"ignoreFunction":true},"cleanupOnClient":true,"windowKey":"__PRELOADED_STATE__"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
